import axios from 'axios';
import router from '../router/index'
export default class {

    constructor(settings = {
        errorStatus: [],
        loginUrl: '',
        loginRouterName: 'login',
        bearerToken: false,
        baseUrl: ''
    }) {
        this.token = "FAIL";
        this.BASE_URL = settings.baseUrl ? settings.baseUrl : '';
        this.errorStatus = settings.errorStatus ? settings.errorStatus : [] // must be array
        this.loginUrl = settings.loginUrl ? settings.loginUrl : ''
        this.loginRouterName = settings.loginRouterName ? settings.loginRouterName : 'login'
        this.bearerToken = settings.bearerToken ? settings.bearerToken : false
    }
    dataMethod = (data) => {
        if (data === undefined) return JSON.stringify(data)
        else if (typeof data.append === 'undefined') return JSON.stringify(data)
        else return data
    };
    get = async (url, data, withToken = true) => await this.request(this.config(url, this.dataMethod(data), withToken, 'get'))
    post = async (url, data, withToken = true) => await this.request(this.config(url, this.dataMethod(data), withToken,))
    put = async (url, data, withToken = true) => await this.request(this.config(url, this.dataMethod(data), withToken, 'put'))
    delete = async (url, data, withToken = true) => await this.request(this.config(url, this.dataMethod(data), withToken, 'delete'))
    login = async (data) => await this.request(this.config(this.loginUrl, this.dataMethod(data), false), true)
    request = (config) => {
        const vm = this
        return axios(config)
            .then(function (response) {
                return { result: 'OK', ...response.data }
            })
            .catch(function (error) {
                const err = JSON.parse(JSON.stringify(error))
                if (vm.errorStatus.includes(err.status)) {
                    router.push({ name: vm.loginRouterName })
                }
                return { ...error.response.data.error, result: 'FAIL' }
            });
    }
    config = (url, data, withToken = true, method = 'post') => {
        const BASE_URL = `${this.BASE_URL}`
        let header = {
            'Content-Type': 'application/json'
        }
        if (withToken && this.token !== 'FAIL') {
            header = {
                'Content-Type': 'application/json',
                Authorization: `${this.bearerToken ? 'Bearer ' : ''}${this.token}`
            }
        } else if (withToken && this.token === 'FAIL') {
            router.push({ name: this.loginRouterName })
        }
        return {
            method,
            url: `${BASE_URL}${url}`,
            headers: header,
            data
        };
    }
}
