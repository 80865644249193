export default {
    state: {
        editReportId: -1,
        createReport: {
            company: {
                status: false,
                name: "",
                email: "",
                taxNo: "",
                address: "",
                id: -2,
            },
            product: { status: false },
            serialNo: { status: false },
            brand: { status: false },
            questions: { status: false, data: [] },
            time: {
                arrivalTime: "",
                departTime: ""
            },
            comment: ""
        }
    },
    mutations: {
        SET_EDIT_REPORT_ID(state, payload) {
            localStorage.setItem('edit', payload)
            state.editReportId = payload
        },
        SET_UPDATE_REPORT(state, payload) {
            state.createReport[payload.key] = payload.data
            localStorage.setItem('report', JSON.stringify(state.createReport))
        },
        SET_REPORT_FROM_LOCALSTORAGE(state) {
            try {
                if (localStorage.getItem('report'))
                    state.createReport = JSON.parse(localStorage.getItem('report'))
                if (localStorage.getItem('edit'))
                    state.editReportId = localStorage.getItem('edit')
            } catch (error) {
                state.createReport = {
                    company: {
                        status: false,
                        name: "",
                        email: "",
                        taxNo: "",
                        address: "",
                        id: -2,
                    },
                    product: { status: false },
                    serialNo: { status: false },
                    brand: { status: false },
                }
            }
        }
    },
    actions: {

    }
}