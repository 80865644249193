export default {
    state: {
        questionGroupList: { id: -1, list: [] },
        questionGroupListIsLoading: true,
        questions: { id: -1, list: [], isLoading: true },
    },
    getters: {
        getQuestionGroupList(state) {
            return state.questionGroupList
        },
        getQuestionGroupListIsLoading(state) {
            return state.questionGroupListIsLoading
        },
        getQuestionList(state) {
            return  state.questions
        }
    },
    mutations: {
        SET_QUESTION_GROUP_LIST_IS_LOADING(state, payload) {
            state.questionGroupListIsLoading = payload
        },
        SET_QUESTION_GROUP_LIST(state, payload) {
            state.questionGroupList = payload;
        },
        SET_QUESTION_LIST(state, payload) {
            state.questions = payload
        }
    },
    actions: {
        async SEND_QUESTION_BY_GROUP({ commit, state }, { id }) {
            const api = this._vm.$api
            console.log("burda");
            commit('SET_QUESTION_LIST', { ...state.questions, isLoading: true })
            const response = await api.get(
                `Questions?groupId=${id}`
            );
            if (response.result === "OK" && response.message === "OK")
                commit('SET_QUESTION_LIST', { id, list: response.data, isLoading: false })
            else commit('SET_QUESTION_LIST', { id, list: [], isLoading: false })
        }
    }
}