<template>
  <nav v-if="pages > 1" aria-label="Page navigation example">
    <ul class="pagination">
      <li v-if="page !== 1" @click="SET_PAGINATION(page - 1)" class="page-item">
        <a class="page-link" href="javascript:void(0)">Previous</a>
      </li>
      <template v-if="pages > 5">
        <li
          v-if="page !== 1"
          class="page-item"
          @click="SET_PAGINATION(1)"
          href="javascript:void(0)"
          :class="{ active: page === 1 }"
        >
          <a class="page-link active">{{ 1 }}</a>
        </li>
        <li
          v-if="page !== 1 && page !== 2"
          class="page-item"
          href="javascript:void(0)"
        >
          <a class="page-link">...</a>
        </li>
        <li
          v-if="page !== 1 && page !== 2"
          class="page-item"
          @click="SET_PAGINATION(page - 1)"
          href="javascript:void(0)"
        >
          <a class="page-link active">{{ page - 1 }}</a>
        </li>
        <li
          class="page-item active"
          @click="SET_PAGINATION(page)"
          href="javascript:void(0)"
        >
          <a class="page-link active">{{ page }}</a>
        </li>
        <li
          v-if="page + 1 < pages"
          class="page-item"
          @click="SET_PAGINATION(page + 1)"
          href="javascript:void(0)"
        >
          <a class="page-link active">{{ page + 1 }}</a>
        </li>
        <li
          v-if="page !== pages && page !== pages - 1"
          class="page-item"
          href="javascript:void(0)"
        >
          <a class="page-link">...</a>
        </li>
        <li
          v-if="page !== pages"
          class="page-item"
          @click="SET_PAGINATION(pages)"
          href="javascript:void(0)"
        >
          <a class="page-link active">{{ pages }}</a>
        </li>
      </template>
      <template v-else>
        <li
          class="page-item"
          @click="SET_PAGINATION(pageCount)"
          v-for="(pageCount, i) in pages"
          href="javascript:void(0)"
          :key="i"
          :class="{ active: page === pageCount }"
        >
          <a class="page-link active">{{ pageCount }}</a>
        </li>
      </template>
      <li
        v-if="page !== pages"
        @click="SET_PAGINATION(page + 1)"
        class="page-item"
      >
        <a class="page-link" href="javascript:void(0)">Next</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    page: {
      default: 1,
    },
    pages: {
      default: 1,
    },
  },
  methods: {
    SET_PAGINATION(page) {
      this.$emit("change", page);
    },
  },
};
</script>

<style>
</style>