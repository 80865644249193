export default {
    state: {
        reports: [],
        reportGroups: []
    },
    getters: {
        getReports(state) {
            return state.reports
        },
    },
    mutations: {
        SET_REPORT_LIST(state, payload) {
            state.reports = payload
        }
    },
    actions: {

    }
}