import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import reports from './modules/reports'
import question from './modules/question'
import createReport from './modules/createReport'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        mailAction: {
            isShow: false,
            isSending: false,
            text: ''
        }
    },
    mutations: {
        SET_PDF_IS_SENDING(state, payload) {
            state.mailAction = payload;
        },
    },
    modules: {
        user,
        reports,
        createReport,
        question
    }
})