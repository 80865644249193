<template>
  <form @submit="HANDLE_SUBMIT">
    <slot />
    <button
      @submit="HANDLE_SUBMIT"
      v-if="!slotButton"
      type="submit"
      :disabled="btnDisabled || loading || dataLoading"
      :class="`btn btn-${btnVariant} ${btnBlock ? '' : 'btn-block'} btn-submit`"
    >
      <span
        v-if="loading || dataLoading"
        class="spinner-border spinner-border-sm mr-2"
        role="status"
        aria-hidden="true"
      ></span>
      {{ btnText }}
    </button>
    <slot name="button" />
  </form>
</template>

<script>
export default {
  data() {
    return {
      slotButton: false,
      dataLoading : false
    };
  },
  props: {
    btnText: {
      default: "Submit",
    },
    type: {
      default: "button",
      type: String,
    },
    btnDisabled: {
      default: false,
      type: Boolean,
    },
    btnVariant: {
      default: "primary",
      type: String,
    },
    btnBlock: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    SET_HANDLE_LOADING(params = true) {
      this.dataLoading = params;
    },
    HANDLE_SUBMIT(e) {
      this.$emit("submit", e, this.SET_HANDLE_LOADING);
    },
    SLOTS_CONTROL() {
      if (Object.keys(this.$slots).includes("button")) this.slotButton = true;
    },
  },
  mounted() {
    this.SLOTS_CONTROL();
  },
};
</script>

<style>
</style>