import Vue from 'vue'
import EralpButton from '../components/ui/EralpButton.vue'
import EralpForm from '../components/ui/EralpForm.vue'
import EralpPagination from '../components/ui/EralpPagination.vue'
import Prototype from './prototype'
import Api from './api'
const EralpApi = new Api({
    errorStatus: [401, 403, 500],
    loginUrl: "Users/Login",
    loginRouterName: "login",
    bearerToken: true,
    // baseUrl: "http://192.168.1.96:5005/",
    baseUrl: "https://api.softservesupport.com.au/",
    // baseUrl: "https://api.softserve.eralpsoftware.net/",
    // baseUrl: "http://3.133.184.223:5005/",
    Authorization: "Authorization",
    result: "cevap"
});
export default {
    install: () => {
        Vue.component('eralp-button', EralpButton);
        Vue.component('eralp-form', EralpForm);
        Vue.component('eralp-pagination', EralpPagination);
        Vue.prototype.$eralp = Prototype
        Vue.prototype.$api = EralpApi
    }
}