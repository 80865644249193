<template>
  <button
    @submit="HANDLE_SUBMIT"
    :type="type"
    :disabled="disabled || loading"
    :class="`btn btn-${variant} ${block ? '' : 'btn-block'} btn-submit`"
  >
    <span
      v-if="loading"
      class="spinner-border spinner-border-sm mr-2"
      role="status"
      aria-hidden="true"
    ></span>
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    type: {
      default: "button",
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    variant: {
      default: "primary",
      type: String,
    },
    block: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    SET_HANDLE_LOADING(params = true) {
      this.loading = params;
    },
    HANDLE_SUBMIT(e) {
      this.$emit("click", e, this.SET_HANDLE_LOADING);
    },
  },
};
</script>

<style>
</style>