import router from '@/router'
export default {
    state: {
        activeUser: {}
    },
    mutations: {
        SET_ACTIVE_USER(state, payload) {
            const vm = this._vm;
            state.activeUser = payload
            vm.$api.token = payload.token;
            localStorage.setItem('user', JSON.stringify(payload));
        }
    },
    actions: {
        async GET_INITIAL_ACTIVE_USER({ commit }) {
            const vm = this._vm;
            const data = JSON.parse(localStorage.getItem('user'));
            if (data === null) return router.push({ name: 'login' })
            const response = await vm.$api.login(data.form);
            if (response.result === 'OK') commit('SET_ACTIVE_USER', { ...response.data, form: data.form });
        }
    }
}