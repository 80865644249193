<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
:root {
  --vs-dropdown-option--active-bg: #5fd0a5;
  --vs-dropdown-option--active-color: #fff;
}
</style>
