import Vue from 'vue'
import App from './App.vue'
import vSelect from 'vue-select'
import EralpComponents from './plugins/eralp'
import VueCarousel from 'vue-carousel';
import VueExcelXlsx from "vue-excel-xlsx";
Vue.config.productionTip = false
import router from './router/index'
import store from './store/index'
Vue.use(VueCarousel);
Vue.use(VueExcelXlsx);
Vue.component('v-select', vSelect)
EralpComponents.install();
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
