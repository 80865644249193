import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: "Home",
        component: () => import(/* webpackChunkName: "app-start" */ "../layouts/default.vue"),
        redirect: `/home`,
        children: [
            {
                path: "/home",
                name: "Dashboard",
                icon: "dashboard",
                isSidebar: true,
                component: () =>
                    import(/* webpackChunkName: "home" */ "../pages/index")
            }, {
                path: "/create-report",
                name: "Create Report",
                icon: "fiber_new",
                isSidebar: true,
                component: () =>
                    import(/* webpackChunkName: "report-group-questions" */ "../pages/createReport/index.vue")
            }, {
                path: "/create-report/:reportId",
                name: "Step 1 : Choose Company",
                icon: "fiber_new",
                isSidebar: false,
                component: () =>
                    import(/* webpackChunkName: "report-group-questions-1" */ "../pages/createReport/chooseCompany/index.vue")
            }, {
                path: "/create-report/:reportId/product",
                name: "Step 2 : Choose Product",
                icon: "fiber_new",
                isSidebar: false,
                component: () =>
                    import(/* webpackChunkName: "report-group-questions-2" */ "../pages/createReport/chooseCompany/chooseProduct/index.vue")
            }, {
                path: "/create-report/:reportId/questions",
                name: "Step 3 : Questions",
                icon: "fiber_new",
                isSidebar: false,
                component: () =>
                    import(/* webpackChunkName: "report-group-questions-3" */ "../pages/createReport/chooseCompany/chooseProduct/questions/index.vue")
            }, {
                path: "/update-report/:reportId",
                name: "Step 1 : Choose Company",
                icon: "fiber_new",
                isSidebar: false,
                component: () =>
                    import(/* webpackChunkName: "update-report-group-questions-1" */ "../pages/updateReport/chooseCompany/index.vue")
            }, {
                path: "/update-report/:reportId/product",
                name: "Step 2 : Choose Product",
                icon: "fiber_new",
                isSidebar: false,
                component: () =>
                    import(/* webpackChunkName: "update-report-group-questions-2" */ "../pages/updateReport/chooseCompany/chooseProduct/index.vue")
            }, {
                path: "/update-report/:reportId/questions",
                name: "Step 3 : Questions",
                icon: "fiber_new",
                isSidebar: false,
                component: () =>
                    import(/* webpackChunkName: "update-report-group-questions-3" */ "../pages/updateReport/chooseCompany/chooseProduct/questions/index.vue")
            }, {
                path: "/reports",
                icon: "settings",
                name: "Settings",
                isSidebar: true,
                component: () =>
                    import(/* webpackChunkName: "reports" */ "../pages/reports/index.vue"),
            }, {
                path: "/companies",
                icon: "domain",
                name: "Companies",
                isSidebar: true,
                component: () =>
                    import(/* webpackChunkName: "companies" */ "../pages/companies/index.vue"),
            }, {
                path: "/companies/:id",
                icon: "domain",
                name: "Company Detail",
                isSidebar: false,
                component: () =>
                    import(/* webpackChunkName: "company-detail" */ "../pages/companyDetail/index.vue"),
            }, {
                path: "/brands",
                icon: "gif",
                name: "Brands",
                isSidebar: true,
                component: () =>
                    import(/* webpackChunkName: "brands" */ "../pages/brands/index.vue"),
            }, {
                path: "/brands/:brandId",
                icon: "domain",
                name: "Products",
                isSidebar: false,
                component: () =>
                    import(/* webpackChunkName: "brands-detail" */ "../pages/models/index.vue"),
            }, {
                path: "/pdf-view",
                icon: "pdf-view",
                name: "Pdf View",
                isSidebar: false,
                component: () =>
                    import(/* webpackChunkName: "pdf-view" */ "../pages/externalReport.vue"),
            }, {
                path: "/settings",
                icon: "pdf-view",
                name: "Settings",
                isSidebar: false,
                component: () =>
                    import(/* webpackChunkName: "settings" */ "../pages/settings.vue"),
            },
            {
                path: "/report-detail/:id",
                icon: "settings",
                name: "Report Detail",
                isSidebar: false,
                component: () =>
                    import(/* webpackChunkName: "report-detail" */ "../pages/reportDetail/index.vue"),
            },
            {
                path: "/reports/:reportId",
                name: "Report Groups",
                icon: "group",
                isSidebar: false,
                component: () =>
                    import(/* webpackChunkName: "report-group" */ "../pages/reportGroups/index.vue")
            }, {
                path: "/reports/:reportId/reportGroups/:reportGroupId",
                name: "Report Group Questions",
                icon: "group",
                isSidebar: false,
                component: () =>
                    import(/* webpackChunkName: "report-group-questions" */ "../pages/reportGroupQuestions/index.vue")
            },
            {
                path: "/products",
                icon: "inventory",
                name: "Products",
                isSidebar: true,
                component: () =>
                    import(/* webpackChunkName: "products" */ "../pages/products/index.vue"),
            },
        ],

    },
    {
        path: "/external-report",
        name: "external-report",
        component: () =>
            import(/* webpackChunkName: "external-report" */ "../pages/externalReport")
    },
    {
        path: "/sign-in",
        name: "login",
        component: () =>
            import(/* webpackChunkName: "sign-in" */ "../pages/signIn")
    }
]

const router = new VueRouter({
    linkActiveClass: "active",
    routes,
    mode: "hash",
});
export default router;
